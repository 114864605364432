/* eslint-disable react/jsx-no-constructed-context-values */
import PropTypes from 'prop-types';
import { Amplify, Auth, Hub } from 'aws-amplify';
import React, { PureComponent, createContext } from 'react';
import {
  COGNITO_USER_ATTRIBUTES,
  HEADERS,
} from '@src/utilities/helper-constants';
import Background from '@base/Background';
import { get } from 'lodash';
import objectMapKeysDeep from '@src/utilities/object-map-keys-deep';
import isJSON from '@src/utilities/is-json';
import { camelCase } from 'change-case';
import { amplify, apollo } from '@config';
import { print } from 'graphql';
import checkPwdQuery from '@queries/check-pwd.gql';
import updatePwdMutation from '@mutations/update-pwd.gql';
import { v4 as uuidv4 } from 'uuid';
import { UUID_PREFIXES } from '@src/utilities/constants';
import Login from './Login';
import PasswordReset from './PasswordReset';
import SignUp from './SignUp';
import SignedIn from './SignedIn';
import PasswordChange from './PasswordChange';

export const AUTH_STATES = {
  LOADING: 'LOADING',
  MY_CHANGE_PASSWORD: 'MY_CHANGE_PASSWORD',
  MY_FORGOT_PASSWORD: 'MY_FORGOT_PASSWORD',
  NEW_PASSWORD_REQUIRED: 'NEW_PASSWORD_REQUIRED',
  SIGNED_IN: 'SIGNED_IN',
  SIGNED_OUT: 'SIGNED_OUT',
};
const AuthContext = createContext({});

const formatValue = (value) => {
  if (isJSON(value)) return JSON.parse(value);
  if (value === 'true') return true;
  if (value === 'false') return false;
  return value;
};

const formatAuthUser = (session) =>
  Object.entries(
    objectMapKeysDeep(session.signInUserSession.idToken.payload, camelCase)
  ).reduce(
    (attributes, [key, value]) => ({
      ...attributes,
      [key]: formatValue(value),
    }),
    {}
  );

class AuthProvider extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      authState: AUTH_STATES.LOADING,
      authUser: {},
      jwt: null,
    };
  }

  async componentDidMount() {
    Amplify.configure(amplify);
    await this.checkAuthenticatedUser();

    // Listen for all auth events.
    Hub.listen('auth', ({ payload: { event } }) => {
      switch (event) {
        case 'signIn':
          this.checkAuthenticatedUser();
          break;
        case 'signOut':
          this.setStateSignOut();
          break;
        default:
          break;
      }
    });
  }

  checkAuthenticatedUser = async () => {
    try {
      const session = await Auth.currentAuthenticatedUser({
        bypassCache: false,
      });
      const authUser = formatAuthUser(session);
      const changePwdRequired = authUser.changePwd;
      this.setState({
        authState: changePwdRequired
          ? AUTH_STATES.MY_CHANGE_PASSWORD
          : AUTH_STATES.SIGNED_IN,
        authUser,
        jwt: get(session, 'signInUserSession.accessToken.jwtToken'),
      });
    } catch (e) {
      this.setStateSignOut();
    }
  };

  getHeaders = () => {
    const { jwt } = this.state;
    const res = {
      'content-type': 'application/json',
      [HEADERS.API_KEY]: apollo.apiKey,
      [HEADERS.TRACE_ID]: uuidv4(),
    };
    if (jwt) {
      res[HEADERS.JWT] = jwt;
    }
    return res;
  };

  checkPwdAPI = async (pwd, email) => {
    const { data, errors } = await (
      await fetch(apollo.url, {
        body: JSON.stringify({
          query: print(checkPwdQuery),
          variables: {
            email,
            password: pwd,
          },
        }),
        headers: this.getHeaders(),
        method: 'POST',
      })
    ).json();

    if (errors) {
      throw new Error('Please contact System administrator');
    }
    const { checkPwd } = data;

    if (checkPwd) {
      throw new Error('This password was used recently.');
    }
  };

  updatePwdAPI = async (pwd, email) => {
    const { data, errors } = await (
      await fetch(apollo.url, {
        body: JSON.stringify({
          query: print(updatePwdMutation),
          variables: {
            email,
            password: pwd,
          },
        }),
        headers: this.getHeaders(),
        method: 'POST',
      })
    ).json();
    if (errors) {
      throw new Error('Please contact System administrator');
    }

    const { updatePwd } = data;
    if (!updatePwd) {
      throw new Error('Please contact System administrator');
    }
  };

  // sign in
  signIn = async ({ password, username }) => {
    const authUser = await Auth.signIn(username, password);
    if (authUser.challengeName) {
      this.setState({
        authState: authUser.challengeName,
        authUser,
      });
    } else {
      await this.checkAuthenticatedUser();
    }
  };

  // forgot password page
  setShowPasswordResetPage = () => {
    this.setState({ authState: AUTH_STATES.MY_FORGOT_PASSWORD });
  };

  changePasswordWithCode = async (email, code, newPassword) => {
    await this.checkPwdAPI(newPassword, email);
    await Auth.forgotPasswordSubmit(email, code, newPassword);
    await this.updatePwdAPI(newPassword, email);
  };

  // change password
  setShowChangePasswordPage = () => {
    this.setState({ authState: AUTH_STATES.MY_CHANGE_PASSWORD });
  };

  changePassword = async (oldPassword, newPassword) => {
    const user = await Auth.currentAuthenticatedUser();
    await this.checkPwdAPI(newPassword);
    await Auth.changePassword(user, oldPassword, newPassword);
    await this.updatePwdAPI(newPassword);
    await this.signOut();
  };

  // sign out
  setStateSignOut = async () => {
    this.setState({
      authState: AUTH_STATES.SIGNED_OUT,
      authUser: {},
    });
  };

  signOut = async () => {
    await Auth.signOut();
    await this.setStateSignOut();
  };

  // complete signup
  setBackToLogin = () => {
    this.setStateSignOut();
  };

  completeNewPasswordChallenge = async ({
    password,
    familyName,
    givenName,
  }) => {
    const { authUser } = this.state;
    // add dbn logic to check
    await Auth.completeNewPassword(authUser, password, {
      [COGNITO_USER_ATTRIBUTES.FAMILY_NAME]: familyName,
      [COGNITO_USER_ATTRIBUTES.GIVEN_NAME]: givenName,
    });
    await this.checkAuthenticatedUser();
  };

  render() {
    const { authState, authUser, jwt } = this.state;
    const { children } = this.props;

    let result = null;

    switch (authState) {
      case AUTH_STATES.LOADING: {
        result = <Background key="so" loading title="" />;
        break;
      }

      case AUTH_STATES.SIGNED_OUT: {
        result = (
          <Background key="so" title="Login">
            <Login
              setShowPasswordResetPage={this.setShowPasswordResetPage}
              signIn={this.signIn}
            />
          </Background>
        );
        break;
      }

      case AUTH_STATES.MY_CHANGE_PASSWORD: {
        result = (
          <Background key="so" title="Change Password">
            <PasswordChange
              changePassword={this.changePassword}
              signOut={this.signOut}
            />
          </Background>
        );
        break;
      }

      case AUTH_STATES.MY_FORGOT_PASSWORD: {
        result = (
          <Background key="so" title="Password Reset">
            <PasswordReset
              passwordReset={this.changePasswordWithCode}
              setBackToLogin={this.setBackToLogin}
            />
          </Background>
        );
        break;
      }

      case AUTH_STATES.NEW_PASSWORD_REQUIRED: {
        result = (
          <Background key="so" title="Complete Sign Up">
            <SignUp
              completeNewPasswordChallenge={this.completeNewPasswordChallenge}
            />
          </Background>
        );
        break;
      }

      case AUTH_STATES.SIGNED_IN: {
        result = (
          <SignedIn
            jwt={jwt}
            setShowChangePasswordPage={this.setShowChangePasswordPage}
            signOut={this.signOut}
            user={authUser}
          >
            {children}
          </SignedIn>
        );
        break;
      }

      default:
        result = <Background key="so" loading />;
    }
    return (
      <AuthContext.Provider
        value={{
          isAuthenticated: true,
          signOut: this.signOut,
          user: authUser,
          userId: `${UUID_PREFIXES.USER}${authUser.cognitoUsername}`,
          userName: `${authUser.givenName} ${authUser.familyName}`,
        }}
      >
        {result}
      </AuthContext.Provider>
    );
  }
}

AuthProvider.propTypes = {
  children: PropTypes.node,
};

AuthProvider.defaultProps = {
  children: null,
};

export { AuthContext, AuthProvider };
